import logo1 from "../../images/partner-logos/air-astana.svg";
import logo2 from "../../images/partner-logos/belavia.svg";
import logo3 from "../../images/partner-logos/china-southern.svg";
import logo4 from "../../images/partner-logos/flydubai.svg";
import logo5 from "../../images/partner-logos/s7.svg";
import logo6 from "../../images/partner-logos/turkish.svg";
import logo7 from "../../images/partner-logos/ural-airline.svg";
import logo8 from "../../images/partner-logos/uzbekistan-airways.svg";

export const partnerLogos = [
  { slug: "partner-1", logo: logo1, id: 1, },
  { slug: "partner-2", logo: logo2, id: 2, },
  { slug: "partner-3", logo: logo3, id: 3, },
  { slug: "partner-4", logo: logo4, id: 4, },
  { slug: "partner-5", logo: logo5, id: 5, },
  { slug: "partner-6", logo: logo6, id: 6, },
  { slug: "partner-7", logo: logo7, id: 7, },
  { slug: "partner-8", logo: logo8, id: 8, },
];
