import { makeStyles } from "@material-ui/core/styles"; 

export const useStyles = makeStyles({
  carouselWrapper: {
    backgroundColor: "#ff7043c9",
    padding: "48px 0",
    margin: "48px 0",

    "& .slick-track": {
      display: "flex",
      alignItems: "center",
      gap: 48
    }
  },

  itemsWrapper: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },

  logo: {
    width: "100%",
    height: "100%",
  }
});