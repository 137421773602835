import bgImage from '../../images/bg-image.png';
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import Link from '../../components/ui/components/Link/Link';
import DehazeIcon from '@material-ui/icons/Dehaze';
import palette from '../palette';

export const GlobalStyle = createGlobalStyle`
  body {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    font-family: Roboto, sans-serif;
    caret-color: ${palette.burningOrange};
    background-color: ${palette.porcelainLight};
    height: 100%;
  }
`;

export const AppContainer = styled.div({
    position: 'relative',
    flex: '1 0 auto',
    zIndex: 1
});

export const MainLogoBlock = styled.div`
    cursor: pointer;

    & > img {
        display: block;
        object-fit: contain;
        height: 44px;
    }
`;

export const StyledButton = styled.button`
    width: ${props => props.widthSize || '174px'};
    height: ${props => props.heightSize || '56px'};
    border-radius: 4px;
    background-color: ${palette.burningOrange};
    font-weight: 500;
    font-size: 20px;
    font-size: ${props => props.fontSize || '20px'};
    color: ${props => props.colorType || palette.white};
    cursor: pointer;
    
    :hover {
        background-color: ${palette.flamingo};
    }
    
    :active {
        background-color: ${palette.thunderbird};
    }
    
    :focus { 
        outline: none;
    }
    
    :disabled {
        background-color: ${palette.sandyBrown};
        color: ${palette.white};
        cursor: default;
        border-color: ${palette.porcelainLight};
    }
`;

export const StyledHeaderMenuButton = styled(DehazeIcon)`
    cursor: pointer;
    padding: 17px 0;
    display: block !important;
`;

export const StyledSearchButton = styled(StyledButton)`
    width: 100%;
    height: 100%;
    display: none;

    @media (min-width: 600px){
        display: inline-block;
    }
`;

export const StyledMobileSearchButton = styled(StyledButton)`
    width: 100%;
    min-height: 52px;
    display: inline-block;

    @media (min-width: 600px){
        display: none;
    }
`;

export const StyledPaymentButton = styled(StyledButton)`
    font-size: 18px;
`;

export const StyledVoidButton = styled(StyledButton)`
    font-size: 16px;
    width: 206px;
`;

export const StyledSupportButton = styled(StyledButton)`
    background-color: ${palette.fruitSalad};
    font-size: 16px;
    width: 206px;
    
    :hover {
        background-color: ${palette.apple};
    }
    
    :active {
        background-color: ${palette.goblinDark};
    }
    
    :focus { 
        outline: none;
    }
`;

export const StyledTicketsOptionsButton = styled(StyledButton)`
    background-color: ${palette.white};
    border-color: ${palette.white};
    font-size: 16px;
    width: 206px;
    color: ${palette.mineShaft};
    
    :hover {
        background-color: ${palette.athensGrey};
    }
    
    :active {
        background-color: ${palette.athensGrey};
    }
    
    :focus { 
        outline: none;
    }
`;

export const SearchBackground = styled.div`
    background-image: url(${bgImage});
    margin-bottom: 30px;
    background-size: cover;
    transform: translateY(-65px);
`;

export const SearchResultBackground = styled.div`
    background-image: url(${bgImage});
    width: 100%;
    background-size: cover;
    position: ${props => props.minimizeSearch ? 'sticky' : 'relative'};
    top: ${props => props.minimizeSearch ? '58px' : '0'};
    left: 0;
    z-index: 40;
    transition: transform .3s ease;

    &.hideHeader {
        transform: translateY(${props => props.minimizeSearch ? '-58px' : '0'});
        transition: transform .3s ease;
    }

    @media screen and (min-width: 1280px){
        position: sticky;
        top: 65px;

        &.hideHeader {
            transform: translateY(-65px);
        }
    }
`;

export const StyledInput = styled.input`
    ${props => props.isPadding && `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

    height: ${props => props.heightSize || '52px'};
    width: ${props => props.widthSize || '297px'};
    
    border-radius: 4px;
    border: solid ${props => props.borderColor ? '2px' : '1px'} ${props => props.borderColor || palette.geyser};
    background-color: ${props => props.disabled ? palette.porcelainLight : palette.white};
    font-size: 18px;
    color: ${props => props.disabled ? palette.submarine : palette.mineShaft};
    text-transform:  ${props => props.textTransform || 'none'};
    
    :focus { 
        outline: none;
        border: solid 1px ${palette.burningOrange};
    }
`;

export const StyledLink = styled(Link)`
    font-size: 16px;
    color: ${palette.white};
    text-decoration: none;
`;

export const InvalidMessage = styled.div`
    font-size: 14px;
    color: ${palette.tallPoppy};
`;

export const WarningMessage = styled.div`
    font-size: 14px;
    color: ${palette.burningOrange};
`;

export const ErrorsFieldBlock = styled.div`
    text-align: left;
`;

export const FormFieldLabel = styled.label`
    font-size: 12px;
    color: ${palette.grayChateau};
    position: absolute;
    top: 10px;
    left: 16px;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
`;

export const Arrow = styled.div`
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
`;

export const ArrowDown = styled(Arrow)`
    border-top: 5px solid ${props => props.isInvert ? palette.mineShaft : palette.white};
`;

export const ArrowUp = styled(Arrow)`
    border-bottom: 5px solid ${props => props.isInvert ? palette.mineShaft : palette.white};
`;

export const SelectArrowDown = styled(ArrowDown)`
    margin-left: 0;
`;

export const InvertSelectArrowDown = styled(SelectArrowDown)`
    border-top: 8px solid ${palette.mineShaft};
`;

export const LinkText = styled.div`
    display: inline-block;
`;

export const ResponsiveInput = styled.input`
    ${props => props.isPadding && `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid ${props => props.borderColor ? '2px' : '1px'} ${props => props.borderColor || palette.geyser};
    background-color: ${props => props.disabled ? palette.porcelainLight : palette.white};
    font-size: 18px;
    color: ${props => props.disabled ? palette.submarine : palette.mineShaft};
    text-transform:  ${props => props.textTransform || 'none'};

    :focus { 
        outline: none;
        border: solid 1px ${palette.burningOrange};
    }
`;
