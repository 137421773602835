import React, { useContext, useEffect } from 'react';

import { Grid, Container, List } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import { useHistory } from 'react-router-dom';

import * as Styled from '../../styles/common/headerMenu/index';
import Link from '../ui/components/Link/Link';
import { useStyles } from '../../containers/common/header/styles';
import { LangContext } from '../../providers/LangProvider';
import { AccessRoleContext } from '../../providers/AccessRoleProvider';
import Config from '../../config/config';
import { hasAdminManagerRoles, hasUserClientAdminManagerRole, hasAdminRole } from '../result/common';
import MainLogo from './mainLogo';
import { normalizePrice } from '../../lib/normalize';
import useSupport from '../../providers/SupportProvider/hook';

const authRoutes = Config.AuthRoutes();

export default function DesktopHeaderMenu(props) {
    const classes = useStyles();
    const { t } = useContext( LangContext );
    const { userRole } = useContext( AccessRoleContext );
    const history = useHistory();

    const { unreadMessages } = useSupport();

    const {
        headerWithoutImg,
        otb,
        partnerCurrency,
        setShowMenu,
        showMenu,
        handleLogout,
        setShowBalance,
        showBalance,
        ownFunds,
        creditLine,
        goToSearchPage,
        goToOrdersPage,
        showOrdersInHeader,
        clientData,
        userData
    } = props;

    const hasAccessToBalance = () => {
        return hasUserClientAdminManagerRole(userRole);
    }

    const balanceGridMouseEnter = () => {
        setShowBalance(true);
        setShowMenu(false);
    }

    const goToSupportOrdersPage = () => {
        setShowMenu(false);
        history.push('/support');
    };

    const OrdersGrid = () => (
        <Grid
            item
            className={ classes.headerMenuItem }
            onClick={ goToOrdersPage }
        >
            { t.orders }
        </Grid>
    );

    const BalanceGrid = () => (
        <Grid
            item
            className={ classes.menuBlock }
            onMouseLeave={ () => setShowBalance(false) }
            onMouseEnter={ balanceGridMouseEnter }
        >
            <div className={ classes.menuLabel }>
                { t.balance }:
                <div className={ classes.balanceTitle }>
                    { normalizePrice(otb) } { partnerCurrency }
                </div>
                { showBalance &&
                    <div className={ classes.balanceContainer }>
                        <div className={ classes.menuBalanceItem }>
                            { t.own_funds }: { normalizePrice(ownFunds) } { partnerCurrency }
                        </div>
                        <div className={ classes.menuBalanceItem }>
                            { t.credit_line }: { normalizePrice(creditLine) } { partnerCurrency }
                        </div>
                        <div className={ classes.menuBalanceItem }>
                            { t.otb }: { normalizePrice(otb) } { partnerCurrency }
                        </div>
                    </div>
                }
            </div>
        </Grid>
    );

    useEffect(() => {
        if (window.location.pathname === "/") {
            setShowMenu(true)
    
            const timer = setTimeout(() => {
                setShowMenu(false)
            }, 5000);
    
            return () => clearTimeout(timer);
        }
    }, [setShowMenu])
    
    
    return (
        <Styled.HeaderContainer headerWithoutImg={ headerWithoutImg }>
            <Container fixed>
                <Grid container justify={ 'space-between' }>
                    <Grid item className={ classes.logoBlock }>
                        <MainLogo { ...props }/>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid
                                item
                                className={ classes.headerMenuItem }
                                onClick={ goToSearchPage }
                            >
                                { t.search }
                            </Grid>
                            { (showOrdersInHeader && hasAdminManagerRoles(userRole)) && OrdersGrid() }
                            { hasAccessToBalance() && BalanceGrid() }
                            <Grid 
                                onMouseEnter={ () => setShowMenu(true) }
                                onMouseLeave={ () => setShowMenu(false) }
                                item className={ classes.menuBlock }>
                                <Grid container>
                                    <div className={classes.accountCircleBlock}>
                                        <AccountCircleIcon className={ classes.circleIcon }/>
                                        {unreadMessages > 0 && (<span className={classes.accountCircleBadge} />)}
                                    </div>
                                    { showMenu &&
                                        <div className={ classes.menuContainer } >
                                            <div className={ classes.triangle }></div>
                                            <div className={classes.menuItem}>
                                                <div className={ classes.clientName }>
                                                    <div>
                                                        { clientData.clientName }
                                                    </div>
                                                    <div className={ classes.clientCode }>
                                                        { `(${ t.code }:${ userData.clientCode })` }
                                                    </div> 
                                                </div>
                                                <div className={ classes.client }>
                                                    { userData.clientMail }
                                                </div>
                                                <List className={ classes.clientType }>
                                                    { t[`${ userData.clientType }_role`]}
                                                </List>
                                                <div className={ classes.partnerName }>
                                                    <div className={ classes.partnerBlock }>
                                                        <div>
                                                            <BusinessIcon className={ classes.buildingIcon }/>
                                                        </div>
                                                        <div className={ classes.clientBlock }>
                                                            <div className={ classes.clientName }>
                                                                { clientData.partnerName }
                                                            </div>
                                                            <div className={ classes.client }>
                                                                { t.code }{ userData.partnerCode }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.root}>
                                                    <div className={ classes.item1 }>
                                                        <Link
                                                            onClick={() => setShowMenu(false)}
                                                            className={classes.linkItem}
                                                            text={t.orders}
                                                            to={authRoutes.orders}
                                                        />
                                                    </div>
                                                    
                                                    <div className={ classes.item }>
                                                        { t.contract_settings }
                                                    </div>
                                                    <div className={ classes.item }>
                                                        {
                                                            hasAdminManagerRoles(userRole) && 
                                                                <Link
                                                                    onClick={() => setShowMenu(false)}
                                                                    className={ classes.nested }
                                                                    text={ t.main }
                                                                    to={ authRoutes.partnerSittings }
                                                                />
                                                        }
                                                        <Link
                                                            onClick={() => setShowMenu(false)}
                                                            className={ classes.nested }
                                                            text={ t.your_profile }
                                                            to={ authRoutes.personalInfo }
                                                        />
                                                        {
                                                            hasAdminManagerRoles(userRole) && 
                                                                <Link
                                                                    onClick={() => setShowMenu(false)}
                                                                    className={ classes.nested }
                                                                    text={ t.users }
                                                                    to={ authRoutes.users }
                                                                />
                                                        }
                                                        <Link
                                                            onClick={() => setShowMenu(false)}
                                                            className={ classes.nested }
                                                            text={ t.passenger_list }
                                                            to={ authRoutes.passengers }
                                                        />
                                                        {
                                                            hasAdminRole(userRole) && 
                                                                <Link
                                                                    onClick={() => setShowMenu(false)}
                                                                    className={ classes.nested }
                                                                    text={ t.affiliated_companies }
                                                                    to={ authRoutes.companies }
                                                                />
                                                        }
                                                    </div>

                                                    { 
                                                        hasAdminManagerRoles(userRole) && 
                                                            <>
                                                                <div className={ classes.item }>
                                                                    { t.finances }
                                                                </div>
                                                                <div className={ classes.item }>
                                                                    <Link
                                                                        onClick={ () => setShowMenu(false) }
                                                                        className={ classes.nested }
                                                                        text={ t.movement_balance }
                                                                        to={ authRoutes.balanceMovements }
                                                                    />
                                                                </div >
                                                            </>
                                                    }

                                                    <div className={ classes.item1 }>
                                                        <div className={classes.linkItemFlex} onClick={goToSupportOrdersPage}>
                                                            <span>Служба поддержки</span>
                                                            {unreadMessages > 0 && (
                                                                <span className={classes.unreadMessagesBadge}>{unreadMessages}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    
                                                    <div>
                                                        <div
                                                            className={ classes.logout }
                                                            onClick={ () => handleLogout() }
                                                        >
                                                            { t.log_out }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Grid>  
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Styled.HeaderContainer>
    );
}