import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import _ from 'lodash';
import * as Img from '../../lib/getImage';
import * as Styled from '../../styles/result/index';
import * as config from '../../config/config';
import noun from 'plural-ru';
import verb from 'plural-ru';
import Config, {
    serverDateFormat,
    serverDateTimeFormat,
    serverDateTimeFormatWithoutYear,
    DateFormatWithoutPoint
} from '../../config/config';
import { ADT, CHD, INS, INF } from "../../containers/search/constants";
import {
    BOOK_STATUS_COLOR,
    IN_PROGRESS_BOOK_CODE,
    BOOKING_DONE_CODE,
    INITIAL_CODE,
    BOOKING_FAIL_CODE,
    PAY_FAIL_CODE,
    TICKETING_DONE_CODE,
    PARTIAL_REFUND_CODE,
    ORDER_FAIL_CODE,
    APPLICATION_REFUND_CODE,
    BOOK_STATUS_CODES,
    PAY_SUCCESS_CODE,
    TICKETING_FAIL_CODE,
    VOID_CODE,
    REFUND_CODE,
    BALANCE_PAYMENT_REQUEST_CODE,
    BALANCE_PAYMENT_APPROVED_CODE,
    CERTIFICATE_ISSUED_CODE
} from '../../containers/order/constants';
import { NP, PS, PSP, SR } from '../../containers/booking/constants';
import { LangContext } from '../../providers/LangProvider';

const TransferTimeFormat = config.webTransferTimeFormat;
const formatForGroupOrders = config.formatForGroupOrders;
const userRoles = Config.UserRoles();

export const RenderDurationTime = (props) => {
    const { t } = useContext( LangContext );

    const hours = Math.trunc(props.duration/3600);
    const minutes = Math.floor((props.duration - hours*3600) / 60);

    return(
        <Box component='span'>
            { hours } { t.hour_short } { minutes } { t.minutes_short_3 }
        </Box>
    );
};

export const GetTime = (data) => {
    const timeArray = data.time.split(' ');
    return timeArray[1];
};

export const RenderAirlineLogo = (data) => {
    const { supplier, horizontally } = data;

    if (supplier) {
        const imgUrl = Img.getSupplierIcon(supplier);

        return <Styled.AirlineLogo horizontally={ horizontally || 'right' } imgUrl={ imgUrl } />
    }

    return <Styled.AirlineLogo />;
};

export const GetCityString = (data) => {
    const { language } = useContext( LangContext );
    const { included, items} = data;
    const { city, airport } = items;

    const cityName = included.city[city].name[language];
    const airportName = included.airport[airport].name[language];

    if (cityName === airportName) {
        return cityName;
    }
    else {
        return (
            <Box component='span'>
                { airportName }, { cityName }
             </Box>
         );
    }
};

export const RenderTechStops = (data) => {
    const { t, language } = useContext( LangContext );
    const { items, included } = data;

    const tehStops = items.map(item => {
        let time = '';

        if(item.duration.hour > 0) {
            time = item.duration.hour + ' ' + t.hour_short + ' ';
        }

        time += item.duration.minute + ' ' + t.minutes_short_3;

        const cityCode = item.city.code;
        
        return included.city[cityCode].name[language] + ' ' + time
    }).join(', ');

    return (
        <div>
            { t.teh_stop }: { tehStops }
        </div>
    );
};

export const FormattedDateTime = (data, withTime=false) => {
    const { language } = useContext( LangContext );
    const momentObj = getMonetObj(data.time, language);

    let dateFormat = serverDateFormat;

    if (withTime) {
        dateFormat = serverDateTimeFormat;
    }

    return momentObj
        .format(dateFormat)
        .replace('.', '');
};

export const FormattedDateTimeWithoutYear = (data, language) => {
    const momentObj = getMonetObj(data, language);

    return momentObj
        .format(serverDateTimeFormatWithoutYear)
        .replace('.', '');
};

export const FormattedForOrdersGroup = (data, language) => {
    return data
        .locale(parserLangCode(language))
        .format(formatForGroupOrders);
};

export const getDayMonthYearFormat = (data, language) => {
    const momentObj = getMonetObj(data, language);

    return momentObj
        .format(DateFormatWithoutPoint)
        .replace('.', '');
};

export const getCreatedTime = (data, language) => {
    const momentObj = getMonetObj(data, language);
    const newFormat = 'DD MMM YYYY, HH:mm';

    return momentObj
        .format(newFormat)
        .replace('.', '');
};

const getMonetObj = (data, language) => {
    return moment(data, TransferTimeFormat)
        .locale(parserLangCode(language));
};

export const GetTransferTime = (route, index, t) => {
    const prevDate = route.segments[index-1].arrival.time;
    const actualDate = route.segments[index].departure.time;
    const prevDateUnix = moment(prevDate, TransferTimeFormat).unix();
    const actualDateUnix = moment(actualDate, TransferTimeFormat).unix();
    const unixDifference = actualDateUnix - prevDateUnix;
    const hours = Math.trunc(unixDifference/3600);
    const minutes = Math.floor((unixDifference - hours*3600) / 60);

    return hours + ' ' +  t.hour_short + ' ' + minutes + ' ' + t.minutes_short_3;
};

export const getPassTypeCountString = (count, type, t) => {
    switch (type) {
        case ADT:
            return noun(count, t.one_adt, t.more_adt, t.many_adt);
        case CHD:
            return noun(count, t.one_chd, t.more_chd, t.many_chd);
        case INS:
            return `${noun(count, t.one_inf, t.more_inf, t.many_inf)} ${t.inf_with_seat_second_label}`;
        case INF:
            return noun(count, t.one_inf, t.more_inf, t.many_inf);
    }
};

export const getBookStatus = (bookingStatus, orderStatus, t) => {
    if (bookingStatus === null && orderStatus !== ORDER_FAIL_CODE ) {
        bookingStatus = IN_PROGRESS_BOOK_CODE;
    }

    if (bookingStatus === null && orderStatus === ORDER_FAIL_CODE ) {
        bookingStatus = ORDER_FAIL_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_CODES, { 'value': bookingStatus });

    return t[bookStatus.code];
};

export const getColorStatus = (status) => {
    if (status === null) {
        status = IN_PROGRESS_BOOK_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_COLOR, { 'value': status });

    return bookStatus.color;
};

export const getBaggageCountString = (count, t) => {
    return noun(count, t.place_one, t.place_more, t.place_many);
};

export const getFlightsCountString = (count, t) => {
    return noun(count, t.flight_one, t.flights_more, t.flights_many);
};

export const getFlightsVebString = (count, t) => {
    return verb(count, t.found_one, t.found_more, t.found_more);
};

export const checkAllowedMK = (bookStatus) => {
    return bookStatus === BOOKING_DONE_CODE
        || bookStatus === PARTIAL_REFUND_CODE
        || bookStatus === APPLICATION_REFUND_CODE
        || bookStatus === PAY_SUCCESS_CODE
        || bookStatus === PAY_FAIL_CODE
        || bookStatus === TICKETING_FAIL_CODE
        || bookStatus === VOID_CODE
        || bookStatus === REFUND_CODE
        || bookStatus === TICKETING_DONE_CODE
        || bookStatus === BALANCE_PAYMENT_REQUEST_CODE
        || bookStatus === BALANCE_PAYMENT_APPROVED_CODE
        || bookStatus === CERTIFICATE_ISSUED_CODE;
};

export const checkIsAllowedCancel = (bookStatus, orderStatus) => {
    return (
            bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BOOKING_FAIL_CODE ||
            bookStatus === PAY_FAIL_CODE ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE
        )
        && orderStatus !== ORDER_FAIL_CODE;
};

export const checkIsAllowBalancePaymentApproved = (bookStatus) => {
    return bookStatus === BALANCE_PAYMENT_REQUEST_CODE;
}

export const checkInProgressStatus = (bookStatus, orderStatus) => {
    return (
            bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null
        )
        && orderStatus !== ORDER_FAIL_CODE;
};

export const checkInAccessToPaymentStatus = (bookStatus, orderStatus) => {
    return (
            bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE
        )
        && orderStatus !== ORDER_FAIL_CODE;
};

export const checkIsAllowedRefund = (status) => {
    return status === TICKETING_DONE_CODE;
};

export const setAuthDataToStore = (props) => {
    const { token_type, access_token, client_code, partner_code } = props;

    localStorage.setItem('token_type', token_type);
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('client_code', client_code);
    localStorage.setItem('partner_code', partner_code);
};

export const removeAuthDataInStore = () => {
    localStorage.removeItem('token_type');
    localStorage.removeItem('access_token');
    localStorage.removeItem('client_code');
    localStorage.removeItem('partner_code');
};

export const getRouteCity = (route, included, language) => {
    const departureSegment = _.first(route.segments);
    const arrivalSegment = _.last(route.segments);
    const departureTime = departureSegment.departure.time;
    const departureCode = departureSegment.departure.city;
    const arrivalCode = arrivalSegment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
};

export const getSegmentCity = (segment, included, language) => {
    const departureTime = segment.departure.time;
    const departureCode = segment.departure.city;
    const arrivalCode = segment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
};

export const getPriceRange = (recommendations, currency) => {
    const sortingRecommendations = sortByMinPrice(recommendations, currency);

    return {
        minValue: Math.floor(_.first(sortingRecommendations).total_price[currency]),
        maxValue: Math.ceil(_.last(sortingRecommendations).total_price[currency])
    }
};

export const sortByMinPrice = (recommendations, currency) => {
    return _.sortBy(recommendations, currency, [ function (rec) {
        return rec['total_price'][currency];
    }]);
};

export const sortByMaxPrice = (recommendations, currency) => {
    return _.sortBy(recommendations, currency, [ function (rec) {
        return rec['total_price'][currency]
    }]).reverse();
};

export const renderServiceClass = (serviceClass, t) => {
    const { code, name } = serviceClass;

    if (!_.isEmpty(code)) {
        return `${ t.class }: ${ name } (${ code })`;
    }

    return `${ t.class }: ${ name }`;
};

export const getAge = (dateOfBirth, format = 'YYYY-MM-DD' ) => {
    return moment().diff(moment(dateOfBirth, format), 'years');
};

export const getAgeMonths = (dateOfBirth, format = 'YYYY-MM-DD' ) => {
    return moment().diff(moment(dateOfBirth, format), 'months');
};

export const getMonthsString = (age, t) => {
    return noun(age, '%d ' + t.month_one, '%d ' + t.months_more, '%d ' + t.months);
};

export const getAgeString = (age, t) => {
    return noun(age, '%d ' + t.year_one, '%d ' + t.year_more, '%d ' + t.years);
};

export const getNoteString = (count, t) => {
    return noun(count, '%d ' + t.record_one, '%d ' + t.record_more, '%d ' + t.record_many);
};

export const getDocumentType = (type, t) => {
    const DOCUMENTS_ITEMS = [
        { value: PS, label: t.russian_passport },
        { value: SR, label: t.birth_certificate },
        { value: PSP, label: t.international_passport },
        { value: NP, label: t.foreign_document }
    ];

    const documentType = _.find(DOCUMENTS_ITEMS, { 'value': type });

    return documentType.label;
};

export const parserLangCode = (language) => {
    return language === 'tj' ? 'tg' : language;
}

export const hasAdminManagerRoles = (userRole) => {
    const { manager, admin } = userRoles;
    return _.includes([manager, admin], userRole);
}

export const hasAdminRole = (userRole) => {
    const { admin } = userRoles;

    return _.includes([admin], userRole);
}

export const hasUserClientAdminManagerRole = (userRole) => {
    const { user_agent, manager, admin } = userRoles;

    return _.includes([user_agent, manager, admin], userRole);
}

export const hasRoleForRefund = (userRole, partnerCode, orderPartnerCode) => {
    return hasUserClientAdminManagerRole(userRole) && partnerCode === _.toString(orderPartnerCode)
}