import styled from 'styled-components'
import iconsOClose from '../../images/krest.png';
import handLuggage from '../../images/handluggerge.png';
import Baggage from '../../images/icons-baggage.png';
import ReturnImg from '../../images/icons-refound.png';
import ExchangeImg from '../../images/icons-change.png';
import {StyledButton} from "../common/index";
import palette from '../palette';

export const MainResultContainer = styled.div`
    width: 100%;
    margin-top: 32px;
`;

export const ResultContainer = styled.div`

`;

export const FilterContainer = styled.div`
    width: 277px;
    float: left;
`;

export const RecommendationBlock = styled.div`
    background-color: ${palette.white};
    padding: 8px 16px;
    margin-bottom: 16px;

    @media screen and (max-width: 960px){
        display: none;
    }
`;

export const RouteTitleContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 7px 0;
    background-size: 24px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: left center;  
`;

export const RouteTitle = styled.div`
    color: ${props => props.themeColor};
    margin-left: 32px;
    font-size: 18px;
    font-weight: 500;
`;

export const RouteMainInfo = styled.div`
    box-sizing: border-box;
    height: 150px;
    border: solid 2px ${props => props.themeColor};
    padding: 10px 10px 10px 20px;
    position: relative;

    @media screen and (max-width: 1280px){
        height: 260px;
    }

    & > div {
        height: 100%;
    }
`;

export const PentagonShape = styled.div`
    width: 10px;
    background: ${props => props.themeColor};
    position: absolute;
    left: 0;
    top: 0;

    :before {
        content: "";
        width: 10px;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 73px solid ${props => props.themeColor};
        border-left: 0px solid transparent;
        border-right: 10px solid transparent;
    }
    :after {
        content: "";
        width: 10px;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 73px solid ${props => props.themeColor};
        border-left: 0px solid transparent;
        border-right: 10px solid transparent;
    }

    @media screen and (max-width: 1280px){
        :before {
            border-bottom: 128px solid ${props => props.themeColor};
        }
        :after {
            border-top: 128px solid ${props => props.themeColor};
        }
    }
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const ButtonContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const ChoosedButton = styled(StyledButton)`
    width: 126px;
    height: 100%;
`;

export const ChoosedButtonBlock = styled(InlineBlock)`
    display flex;
    justify-content: flex-end;
    height: 48px;
    position: sticky;
    bottom: 0;
`;

export const AmountBlock = styled.div`
    display: flex;
    align-items: center;
    color: ${palette.mineShaft};
    font-size: 34px;
    font-weight: 500;

    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`;

export const AmountSubmitBlock = styled.div`
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1280px){
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`;

export const PriceInfoWithTooltipBlock = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

export const PriceInfoTooltipBlock = styled.div`
    padding: 0;
`;

export const PriceInfoTooltipRow = styled.div`
    font-size: 13px;
    padding: 2px 0;
`;

export const SupplierBlock = styled.div`
    color: ${palette.grayChateau};
    font-size: 14px;
`;

export const TransfersArrowImg = styled.div`
    /* width: 184px; */
    height: 10px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
`;

export const TimeRouteBlock = styled.div`
    color: ${palette.mineShaft};
    font-size: 24px;
    font-weight: 500;
`;

export const RouteBlock = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${palette.grayChateau};
`;

export const StringSpan = styled.span`
    margin-left: 3px;
`;

export const InlineTable = styled.div`
    display: inline-table;
`;

export const AirlineLogoBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 100%;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position:  ${props => props.horizontally} center;

    @media screen and (max-width: 1280px){
        background-position: center center;
    }
`;

export const DepartureInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const DurationBlock = styled.div`
    text-align: center;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.grayChateau};
    /* margin-left: 16px; */
`;

export const ArrivalInfo = styled.div`
    text-align: left;
    position: relative;
    /* margin-left: 16px; */
`;

export const ShortTariffInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const ClassBlock = styled.div`
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: ${palette.mineShaft};
`;

export const AdditionalInformationShort = styled.div`
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.grayChateau};
    margin-top: 4px;
`;

export const DefaultIconOption = styled(InlineBlock)`
    height: 13px;
    width: 13px;
    background-size: 13px;
    background-repeat: no-repeat;
    background-position:  center center;
    margin-right: 9px;
`;

export const HandLuggageIcon = styled(DefaultIconOption)`
    background-image: url(${handLuggage});
`;

export const BaggageIcon = styled(DefaultIconOption)`
    background-image: url(${Baggage});
`;

export const ReturnIcon = styled(DefaultIconOption)`
    background-image: url(${ReturnImg});
`;

export const ExchangeIcon = styled(DefaultIconOption)`
    background-image: url(${ExchangeImg});
`;

export const freeSeatsLabelBlock = styled(InlineBlock)`
    vertical-align: 1px;
    padding-left: 12px;
`;

export const NotFoundRecommContainer = styled.div`
    margin: auto;
    max-width: 500px;
    padding: 200px 15px;
    text-align: center;
`;

export const NotFoundRecommMargin = styled.div`
    position: relative;
`;

export const NotFoundRecommFirstBlock = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const NotFoundRecommSecondBlock = styled.div`
    font-size: 18px;
`;

export const ChangeTariffContainer = styled(ShortTariffInfo)`
    cursor: pointer;
    width: 100%;
    text-align: right;
`;

export const TariffChangeBlock = styled(InlineTable)`
    margin: 2px 0 0;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.burningOrange};
    text-align: center;
    cursor: pointer;
`;

export const RightTariffChangeBlock = styled(TariffChangeBlock)`
    border-left: solid 1px ${palette.geyser};
    padding-left: 10px;
    margin-left: 10px;
`;

export const TariffCloseBlock = styled(TariffChangeBlock)`
    font-size: 16px;
`;

export const HideRouteTariffContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Lowcost = styled(InlineTable)`
    color: ${palette.white};
    width: 100px;
    border-radius: 4px;
    background-color: ${palette.fruitSalad};
    text-align: center;
`;
export const Charter = styled.div`
    color: ${palette.white};
    width: 100px;
    border-radius: 4px;
    background-color: ${palette.pastelGreen};
    text-align: center;
    margin-right: 5px;
`

export const StyledHideTariffsButton= styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${iconsOClose});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position:  center left;
    /* display: inline-table; */
    position: relative;
    /* top: 7px; */
`;

export const RouteAdditionallyInfo = styled.div`
    border: solid 2px ${props => props.themeColor};
    border-top: none;
`;

export const SessionContainer = styled.div`
    padding-bottom: 6px;
`;

export const ProviderContainer = styled.div``;

export const ProviderBlock = styled.div`
    font-size: 14px;
`;

export const SortingContainer = styled.div`
    padding: 24px 0 24px 0;
`;